import face0 from './assets/salvinification/face0.png';
import face1 from './assets/salvinification/face1.png';

import DressDefault from './assets/salvinification/Ken.png';
import Dress0 from './assets/salvinification/0.png';
import Dress1 from './assets/salvinification/1.png';
import Dress2 from './assets/salvinification/2.png';
import Dress3 from './assets/salvinification/3.png';
import Dress4 from './assets/salvinification/4.png';
import Dress5 from './assets/salvinification/5.png';
import Dress6 from './assets/salvinification/6.png';
import Dress7 from './assets/salvinification/7.png';
import Dress8 from './assets/salvinification/8.png';
import Dress9 from './assets/salvinification/9.png';
import Dress10 from './assets/salvinification/10.png';
import Dress11 from './assets/salvinification/11.png';
import Dress12 from './assets/salvinification/12.png';
import Dress13 from './assets/salvinification/13.png';
import Dress14 from './assets/salvinification/14.png';
import Dress15 from './assets/salvinification/15.png';
import Dress16 from './assets/salvinification/16.png';
import Dress17 from './assets/salvinification/17.png';
import Dress18 from './assets/salvinification/18.png';
import Dress19 from './assets/salvinification/19.png';
import Dress20 from './assets/salvinification/20.png';
import Dress21 from './assets/salvinification/21.png';
import Dress22 from './assets/salvinification/22.png';
import Dress23 from './assets/salvinification/23.png';
import Dress24 from './assets/salvinification/24.png';
import Dress25 from './assets/salvinification/25.png';
import Dress26 from './assets/salvinification/26.png';
import Dress27 from './assets/salvinification/27.png';
import Dress28 from './assets/salvinification/28.png';
import Dress29 from './assets/salvinification/29.png';
import Dress30 from './assets/salvinification/30.png';
import Dress31 from './assets/salvinification/31.png';
import Dress32 from './assets/salvinification/32.png';
import Dress33 from './assets/salvinification/33.png';
import Dress34 from './assets/salvinification/34.png';
import Dress35 from './assets/salvinification/35.png';
import Dress36 from './assets/salvinification/36.png';
import Dress37 from './assets/salvinification/37.png';
import Dress38 from './assets/salvinification/38.png';
import Dress39 from './assets/salvinification/39.png';
import Dress40 from './assets/salvinification/40.png';
import Dress41 from './assets/salvinification/41.png';
import Dress42 from './assets/salvinification/42.png';
import Dress43 from './assets/salvinification/43.png';
import Dress44 from './assets/salvinification/44.png';
import Dress45 from './assets/salvinification/45.png';
import Dress46 from './assets/salvinification/46.png';
import Dress47 from './assets/salvinification/47.png';
import Dress48 from './assets/salvinification/48.png';
import Dress49 from './assets/salvinification/49.png';
import Dress50 from './assets/salvinification/50.png';
import Dress51 from './assets/salvinification/51.png';
import Dress52 from './assets/salvinification/52.png';
import Dress53 from './assets/salvinification/53.png';
import Dress54 from './assets/salvinification/54.png';
import Dress55 from './assets/salvinification/55.png';
import Dress56 from './assets/salvinification/56.png';
import Dress57 from './assets/salvinification/57.png';
import Dress58 from './assets/salvinification/58.png';
import Dress59 from './assets/salvinification/59.png';
import Dress60 from './assets/salvinification/60.png';
import Dress61 from './assets/salvinification/61.png';
import Dress62 from './assets/salvinification/62.png';
import Dress63 from './assets/salvinification/63.png';
import Dress64 from './assets/salvinification/64.png';
import Dress65 from './assets/salvinification/65.png';
import Dress66 from './assets/salvinification/66.png';
import Dress67 from './assets/salvinification/67.png';
import Dress68 from './assets/salvinification/68.png';
import Dress69 from './assets/salvinification/69.png';
import Dress70 from './assets/salvinification/70.png';
import Dress71 from './assets/salvinification/71.png';
import Dress72 from './assets/salvinification/72.png';
import Dress73 from './assets/salvinification/73.png';
import Dress74 from './assets/salvinification/74.png';
import Dress75 from './assets/salvinification/75.png';
import Dress76 from './assets/salvinification/76.png';
import Dress77 from './assets/salvinification/77.png';
import Dress78 from './assets/salvinification/78.png';
import Dress79 from './assets/salvinification/79.png';
import Dress80 from './assets/salvinification/80.png';
import Dress81 from './assets/salvinification/81.png';
import Dress82 from './assets/salvinification/82.png';
import Dress83 from './assets/salvinification/83.png';
import Dress84 from './assets/salvinification/84.png';
import Dress85 from './assets/salvinification/85.png';
import Dress86 from './assets/salvinification/86.png';
import Dress87 from './assets/salvinification/87.png';
import Dress88 from './assets/salvinification/88.png';
import Dress89 from './assets/salvinification/89.png';
import Dress90 from './assets/salvinification/90.png';
import Dress91 from './assets/salvinification/91.png';
import Dress92 from './assets/salvinification/92.png';
import Dress93 from './assets/salvinification/93.png';
import Dress94 from './assets/salvinification/94.png';
import Dress95 from './assets/salvinification/95.png';
import Dress96 from './assets/salvinification/96.png';
import Dress97 from './assets/salvinification/97.png';
import Dress98 from './assets/salvinification/98.png';
import Dress99 from './assets/salvinification/99.png';
import Dress100 from './assets/salvinification/100.png';
import Dress101 from './assets/salvinification/101.png';
import Dress102 from './assets/salvinification/102.png';
import Dress103 from './assets/salvinification/103.png';
import Dress104 from './assets/salvinification/104.png';
import Dress105 from './assets/salvinification/105.png';
import Dress106 from './assets/salvinification/106.png';
import Dress107 from './assets/salvinification/107.png';
import Dress108 from './assets/salvinification/108.png';
import Dress109 from './assets/salvinification/109.png';




export const faces = [
  face0,
  face1
];

export const dresses = [
  DressDefault,
  Dress0,
  Dress1,
  Dress2,
  Dress3,
  Dress4,
  Dress5, 
  Dress6,
  Dress7,
  Dress8,
  Dress9,
  Dress10,
  Dress11,
  Dress12,
  Dress13,
  Dress14,
  Dress15,
  Dress16,
  Dress17,
  Dress18,
  Dress19,
  Dress20,
  Dress21,
  Dress22,
  Dress23,
  Dress24,
  Dress25,
  Dress26,
  Dress27,
  Dress28,
  Dress29,
  Dress30,
  Dress31,
  Dress32,
  Dress33,
  Dress34,
  Dress35,
  Dress36,
  Dress37,
  Dress38,
  Dress39,
  Dress40,
  Dress41,
  Dress42,
  Dress43,
  Dress44,
  Dress45,
  Dress46,
  Dress47,
  Dress48,
  Dress49,
  Dress50,
  Dress51,
  Dress52,
  Dress53,
  Dress54,
  Dress55,
  Dress56,
  Dress57,
  Dress58,
  Dress59,
  Dress60,
  Dress61,
  Dress62,
  Dress63,
  Dress64,
  Dress65,
  Dress66,
  Dress67,
  Dress68,
  Dress69,
  Dress70,
  Dress71,
  Dress72,
  Dress73,
  Dress74,
  Dress75,
  Dress76,
  Dress77,
  Dress78,
  Dress79,
  Dress80,
  Dress81,
  Dress82,
  Dress83,
  Dress84,
  Dress85,
  Dress86,
  Dress87,
  Dress88,
  Dress89,
  Dress90, 
  Dress91,
  Dress92,
  Dress93,
  Dress94,
  Dress95,
  Dress96,
  Dress97,
  Dress98,
  Dress99,
  Dress100,
  Dress101,
  Dress102,
  Dress103,
  Dress104,
  Dress105,
  Dress106,
  Dress107,
  Dress108,
  Dress109,
];

export const names = [
  "", 
  "Spockvini", 
  "S.W.A.Tvini", 
  "Salvini carramba", 
  "Salvini ufficiale", 
  "Salvini pig",
  "Salvini nostromo",
  "Salvini the templar",
  "Salvini barracellare",
  "Swissvini",
  "Cheerlini",
  "Salvini IL FINANZIERE",
  "Astro Salvini",
  "Salvo texas ranger",
  "Salvigile",
  "Obi Salv Kenobi",
  "Salvininja",
  "Mr.Salvastic",
  "Salvini pompiere",
  "Fra' Salvini",
  "Salvini migrant-buster",
  "Salvocop",
  "Salvini dei 1000",
  "Salvinion",
  "Power Salvin",
  "Salvo X Meridio",
  "Salvini Sailor Moon",
  "Super salvyan",
  "Salvini globetrotter",
  "Salvinella",
  "Salvini bayclose",
  "Kobra-Salv",
  "Scottish Salvini",
  "Royal Salvini",
  "Mr.Salvbean",
  "Scoutvini",
  "Salvazone",
  "Salvini HeMan",
  "Buzz Salvyear",
  "Austin Salvini",
  "SSalvini",
  "Matteo Balotelli",
  "Salvini ladro",
  "Shaolvini",
  "Salviron man",
  "Salvini CRI",
  "Soviet Salvini",
  "Cardinal Salvini",
  "S'Alvagnan",
  "Salvy Potter",
  "Adrian Salvini",
  "Salvini matador",
  "Sambini",
  "Telesalvin",
  "Salvini Cooper",
  "Aladini",
  "Sulk",
  "Salvini Uzumaki",
  "Wolverini",
  "One punch Salvini",
  "Sandalf",
  "Salvigolas",
  "Cetriolo Salvini",
  "Salvini aggiustatutto",
  "Rick Salvchez",
  "Matteo Auditore",
  "Papa Salvesco",
  "Regina Elisalvetta",
  "Mugiwara no Salvini",
  "Buddhini",
  "Matteo Bonaparte",
  "Salvini Z",
  "Salvini Morpheus",
  "Gargalvini",
  "Salvini l'unno",
  "Spidersalv",
  "Supersalv",
  "Salvini Zenigata",
  "Banda Salvotti",
  "Salvini team Racket",
  "Salv-Bill",
  "OPA Salvini style",
  "Salvini Maui",
  "Salv Branningan",
  "Matteo il barbaro",
  "Salvini dio della guerra",
  "$alvini €bbasta",
  "BIG SALVO",
  "Borattino",
  "Salvis Presley",
  "Oliver Salvton",
  "Ronald McSalvini",
  "Salien",
  "Salvatar",
  "Wookvini",
  "Salvpool",
  "Salvini Kruger",
  "Salvini witcher",
  "Giorno Mattea",
  "Salv Bond",
  "Jason Salvhoorhees",
  "Salv Cena",
  "Ken il politico",
  "Salvini l'eletto",
  "Salvator",
  "Salvini schockante",
  "Sub-vini",
  "Salvinator",
  "Salvini the rock",
  "Salvini immigrato",
  "Salvini Wassim"
];